<template>
  <div class="presences">
    <div class="coverDetail">
      <h2>{{overviewClassAipPresence.tingkat}}-{{overviewClassAipPresence.simbol}} | {{overviewClassAipPresence.mapel}}</h2>
    </div>
    <div class="contentDetail">
      <div class="detail-slot">
        <a-row type="flex" justify="space-between">
          <a-col :xs="12" :lg="6">
            <h3 class="type">Subject</h3>
            <p class="value">{{overviewClassAipPresence.mapel}}</p>
          </a-col>
          <a-col :xs="12" :lg="6">
            <h3 class="type">Class</h3>
            <p
              class="value"
            >{{overviewClassAipPresence.tingkat}}-{{overviewClassAipPresence.simbol}} ({{overviewClassAipPresence.students}} Students)</p>
          </a-col>
          <a-col :xs="12" :lg="6" class="mt-4 mt-lg-0">
            <h3 class="type">Session Progress</h3>
            <p class="value">
              <span class="font-weight-bold">{{overviewClassAipPresence.current_week}}</span>
              / {{overviewClassAipPresence.total_week}} Session
            </p>
          </a-col>
          <a-col :xs="12" :lg="6" class="mt-4 mt-lg-0">
            <h3 class="type">Assignments</h3>
            <p class="value">{{overviewClassAipPresence.total_assignments}} Topic</p>
          </a-col>
        </a-row>
        <a-row class="mt-4">
          <a-col :span="24">
            <a-divider orientation="left">
              <h3>Assignment in Progress</h3>
            </a-divider>
          </a-col>
        </a-row>
        <a-row :gutter="32" class="progress-card">
          <div v-for="(item, index) in aapAips" :key="index">
            <a-col :sm="24" :md="12" :lg="8">
              <CardProgress :item="item" />
            </a-col>
          </div>
        </a-row>
        <a-row class="mt-4">
          <a-col :span="24">
            <a-divider orientation="left">
              <h3>Assignment & Presences</h3>
            </a-divider>
          </a-col>
          <a-col :span="24">
            <a-table
              class="table-scroll"
              :pagination="{ pageSize: 30, hideOnSinglePage: true }"
              :columns="columns"
              :data-source="listAipPresencesMurids"
              :scroll="{ x: 1300 }"
              bordered
            >
              <span slot="ClassPresences" slot-scope="text">
                <div v-if="text >= 70 && text <= 100">
                  <a-tag color="green">{{text}} %</a-tag>
                </div>
                <div v-else-if="text >= 35 && text <= 69">
                  <a-tag color="orange">{{text}} %</a-tag>
                </div>
                <div v-else-if="text >= 0 && text <= 34">
                  <a-tag color="red">{{text}} %</a-tag>
                </div>
              </span>
              <span slot="AverageGrade" slot-scope="text">
                <div v-if="text >= 70 && text <= 100">
                  <a-tag color="green">{{text}}</a-tag>
                </div>
                <div v-else-if="text >= 35 && text <= 69">
                  <a-tag color="orange">{{text}}</a-tag>
                </div>
                <div v-else-if="text >= 0 && text <= 34">
                  <a-tag color="red">{{text}}</a-tag>
                </div>
              </span>
            </a-table>
          </a-col>
        </a-row>
      </div>
    </div>
  </div>
</template>

<script>
import CardProgress from '@/components/app/Assignment/CardAssignment'
const columns = [
  {
    title: 'No',
    width: 70,
    dataIndex: 'No',
    key: 'No',
    // fixed: 'left',
  },
  {
    title: 'Name',
    width: 250,
    dataIndex: 'Name',
    key: 'Name',
    // fixed: 'left',
  },
  {
    title: 'Class Presences (%)',
    width: 120,
    dataIndex: 'ClassPresences',
    key: 'ClassPresences',
    // fixed: 'left',
    scopedSlots: { customRender: 'ClassPresences' },
  },
  {
    title: 'Average Grade',
    dataIndex: 'AverageGrade',
    // fixed: 'right',
    width: 100,
    scopedSlots: { customRender: 'AverageGrade' },
  },
  {
    title: 'Assignment Grade',
    align: 'center',
    children: '',
    //   {
    //     title: '#1',
    //     dataIndex: 'assignment1',
    //     key: 'assignment1',
    //     width: 100,
    //   },
    //   {
    //     title: '#2',
    //     dataIndex: 'assignment2',
    //     key: 'assignment2',
    //     width: 100,
    //   },
    //   {
    //     title: '#3',
    //     dataIndex: 'assignment3',
    //     key: 'assignment3',
    //     width: 100,
    //   },
    //   {
    //     title: '#4',
    //     dataIndex: 'assignment4',
    //     key: 'assignment4',
    //     width: 100,
    //   },
    //   {
    //     title: '#5',
    //     dataIndex: 'assignment5',
    //     key: 'assignment5',
    //     width: 100,
    //   },
    //   {
    //     title: '#6',
    //     dataIndex: 'assignment6',
    //     key: 'assignment6',
    //     width: 100,
    //   },
    // ],
  },
]

const data = [
  {
    key: '1',
    name: 'John Brown',
    age: 32,
    assignment1: 92,
    assignment2: 92,
    avgGrade: 92,
  },
  {
    key: '2',
    name: 'Jim Green',
    age: 40,
    assignment1: 92,
    assignment2: 92,
    avgGrade: 92,
  },
]
export default {
  components: {
    CardProgress,
  },
  data() {
    return {
      columns,
      data,

    }
  },
  created() {
    this.$store.dispatch('slot/FETCH_AIP_AAP_TEACHER', {
      idGuru: this.user.id,
      idKelas: this.$route.params.id,
      idMataPelajaran: this.$route.params.idMapel,
    })
    this.$store.dispatch('slot/FETCH_OVERVIEW_CLASS_AAP_TEACHER', {
      idGuru: this.user.id,
      idKelas: this.$route.params.id,
      idMataPelajaran: this.$route.params.idMapel,
    })
      .then(data => {
        this.columns[4].children = data
      })
  },
  computed: {
    user() {
      return this.$store.state.user.user
    },
    overviewClassAipPresence() {
      return this.$store.state.slot.overviewClassAipPresence
    },
    listAipPresencesMurids() {
      return this.$store.state.slot.listAipPresencesMurids
    },
    assignmentColumns() {
      return this.$store.state.slot.assignmentColumns
    },
    aapAips() {
      return this.$store.state.slot.aapAips
    },
  },
}
</script>

<style lang="scss">
.presences {
  .coverDetail {
    width: 100%;
    min-height: 145px;
    background-color: #008080;
    border-radius: 10px;
    padding: 40px;
    color: #ffffff;
  }

  h2 {
    color: #ffffff;
  }

  .contentDetail {
    width: 100%;
    min-height: 20vh;
    background-color: white;
    padding: 40px;
    z-index: 11;
    margin-top: -10px;
    border-radius: 10px;
    box-shadow: 0 0 10px #0419301a;
    margin-left: 1px;
  }

  .contentDetail .detail-slot {
    padding: 10px 0;
  }

  .table-scroll {
    .ant-spin-nested-loading .ant-spin-container .ant-table {
      overflow-x: auto;
    }
  }
}
</style>
